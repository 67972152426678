import {Card, Flex} from "antd";
import {useEffect, useState} from "react";
import {requestFn} from "../helpers";
import {Link} from "react-router-dom";

function formatTime(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${formattedSeconds}`;
}


function formatDateWithoutYear(timestamp) {
    const date = new Date(timestamp);
    const month = date.toLocaleString('cs-CS');
    return month.replace(/202\d/g, '').split(':').slice(0, -1).join(':');
}


const average = (arr) => Math.round(arr.reduce((a, b) => a + b, 0) / arr.length);

export const Stats = ({token}) => {
    const [scope, setScope] = useState(30)
    const [error, setError] = useState('')
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const getData = async () => {
        await requestFn('GET', '/events', token, setData, setError)
        setLoading(false)
    }

    const tabList = [
        {
            key: 30,
            tab: '30',
        },
        {
            key: 60,
            tab: '100',
        },
        {
            key: 100,
            tab: '100',
        },
        {
            key: 250,
            tab: '250',
        },
    ]

    useEffect(() => {
        getData()
    }, []);

    const testsData = data.filter((item) => item.type === 'test')
    const takeLast = testsData.slice(Math.max(testsData.length - scope, 1))
    const testWithTime = takeLast.filter((item) => item.updateData?.newTestData?.additionalData?.testTime)
    const testTimes = testWithTime.map((item) => item.updateData.newTestData.additionalData.testTime);

    return (
        <div style={{
            padding: 24,
        }}>
            {}
            <Card
                style={{ width: '100%' }}
                title={`Test time average in last ${scope} devices: ${loading ? 'Loading...' : formatTime(average(testTimes))}`}
                tabList={tabList}
                activeTabKey={scope}
                onTabChange={setScope}
            >
                <Flex>
                    <div>
                        {takeLast.reverse().map((item) => {
                            const testData = item.updateData?.newTestData
                            const testResult = testData?.testData?.every((item) => item.test_status === 'SUCCESS') ? 'PASSED' : 'FAILED'
                            return testData?.additionalData?.testTime && (
                                <div key={item.timestamp}>
                                    <div style={{display: 'flex'}}>
                                        {`${formatDateWithoutYear(testData.timestamp)} - `}
                                        <Link to={`/device/${item?.suid}`}>{item?.suid}</Link>
                                        <div>{` - `}{testResult} - {formatTime(testData.additionalData.testTime)} </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Flex>
            </Card>
        </div>
    )
}
