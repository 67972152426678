import {useEffect, useState} from "react";
import {Button, Flex, Input, Menu, Space, Tag} from "antd";
import {PAGES} from "./constants";
import {requestFn} from "./helpers";
import {useLocation, useNavigate} from "react-router";

export const TopMenu = ({token, setKey, page}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const savedToken = localStorage.getItem('workerToken') || ''
  const [alias, setAlias] = useState('')
  const [localKeyValue, setLocalKey] = useState(savedToken)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const handleVerify = async () => {
    setLoading(true)
    await requestFn(
      'GET',
      '/key',
      localKeyValue,
      ({alias}) => {
        setKey(localKeyValue)
        setAlias(alias)
        setLocalKey(null)
        localStorage.setItem('workerToken', localKeyValue)
        setError('')
      },
      setError
    )
    setLoading(false)
    setLocalKey('')
  }

  const items = [
    {
      key: PAGES.UPLOAD,
      label: 'Upload',
    },
    {
      key: PAGES.OVERVIEW,
      label: 'Devices'
    },
    {
      key: PAGES.EVENTS,
      label: 'Events'
    },
    {
      key: PAGES.STATS,
      label: 'Stats'
    }
  ]

  useEffect(() => {
    savedToken && handleVerify()
  }, [savedToken]);

  return (
    <Flex
      align='center'
      style={{borderBottom: '1px solid gray', padding: '0 25px'}}
      justify='space-between'
    >
      <Space direction='horizontal' size='large'>
        <Space style={{marginRight: 40}} align='baseline'>
          where's my
          <div
            style={{
              fontSize: '20px',
              fontWeight: 900,
              color: 'orange'
            }}
          >
            Origin
          </div>

          v0.8
          {process.env.REACT_APP_ENV === 'dev' && 'dev'}
        </Space>
        <Menu
          style={{width: 300}}
          mode='horizontal'
          selectedKeys={location.pathname.split('/').filter(Boolean)}
          onClick={({key}) => navigate(key)}
          items={items}
        />
        {!token && (
          <Space direction='vertical'>
            <Space>
              <Input
                value={localKeyValue}
                onChange={(e) => setLocalKey(e.target.value)}
              />
              <Button
                loading={loading}
                onClick={handleVerify}
              >
                Verify key
              </Button>
            </Space>
            {error && (
              <Tag color='error'>
                {error}
              </Tag>
            )}
          </Space>
        )}
      </Space>
      <Space>
        {alias}
        {token && (
          <Button
            danger
            onClick={() => {
              setKey(null)
              setAlias('')
              setLocalKey('')
              localStorage.removeItem('workerToken')
            }}
          >
            Logout
          </Button>
        )}
      </Space>
    </Flex>
  )
}
